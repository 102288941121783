import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import Label from '../label';
import InfoModal from '../info-modal';

// Style imports
import '../../sass/components/input-switch.scss';

const InputSwitch = ({
    className,
    variant,
    id,
    name,
    disabled,
    required,
    children,
    onChange,
    checked,
    modalHeading,
    modalContent,
}) => {
    // Class definitions
    const baseClass = 'input-switch';
    const variantClass = variant ? `input-switch--${variant}` : '';
    const customClass = className;
    const checkedClass = checked ? 'input-switch--checked' : '';
    const classes = [baseClass, variantClass, customClass, checkedClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            <input
                className='input-switch__input'
                id={id}
                name={name}
                type="checkbox"
                onChange={onChange}
                checked={checked}
                aria-checked={checked}
                disabled={disabled}
                required={required}
            />

            <Label
                htmlFor={id}
                disabled={disabled}
                required={required}
            >
                {children}
 
                {(modalContent && !disabled) && (
                    <InfoModal heading={modalHeading}>
                        {modalContent}
                    </InfoModal>
                )}
            </Label>
        </div>
    );
};

InputSwitch.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    modalHeading: PropTypes.string,
    modalContent: PropTypes.node,
};

export default InputSwitch;