/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import InfoModal from '../info-modal';

// Style Imports
import '../../sass/components/result-listing.scss';

const ResultListing = ({
    children,
    select,
    selectID,
    selectOnChange,
    selectValue,
    selectDefaultValue,
    summary,
}) => {
    return (
        <>
            {select && (
                <div className='result-listing__select'>
                    <label className='result-listing__label' htmlFor={selectID}>Filter results:</label>

                    <div className='result-listing__input-wrapper'>
                        <select
                            className="result-listing__input"
                            id={selectID}
                            name={selectID}
                            onChange={selectOnChange}
                            value={selectValue}
                            defaultValue={selectDefaultValue}
                        >
                            {select.map((option, i) => (
                                <option value={option.value} key={i}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            
            <section className='result-listing' aria-label='Your results'>
                <div className='result-listing__inner'>
                    {children}
                </div>

                {summary && (
                    <div className='result-listing__summary'>
                        {summary}
                    </div>
                )}
            </section>
        </>
    );
};

ResultListing.defaultProps = {
    selectDefaultValue: 'year',
};

ResultListing.propTypes = {
    children: PropTypes.node.isRequired,
    select: PropTypes.array,
    selectID: PropTypes.string,
    selectOnChange: PropTypes.func,
    selectValue: PropTypes.string,
    selectDefaultValue: PropTypes.string,
    summary: PropTypes.node,
};

ResultListing.Item = ({
    heading,
    result,
    subheading,
    modalHeading,
    modalContent,
}) => {
   return (
        <article className='result-listing__item'>
            <div className='result-listing__heading-wrapper'>
                <h3 className='result-listing__heading'>
                    <span className={modalContent ? ' m-right--x-small' : ''}>
                        {heading}
                    </span>

                    {modalContent && (
                        <InfoModal heading={modalHeading}>
                            {modalContent}
                        </InfoModal>
                    )}
                </h3>
            </div>

            <p className='result-listing__result'>{result}</p>

            {subheading && (
                <p className='result-listing__subheading'>{subheading}</p>
            )}
        </article>
   );
};

ResultListing.Item.propTypes = {
    heading: PropTypes.string.isRequired,
    result: PropTypes.any.isRequired,
    subheading: PropTypes.string,
    modalHeading: PropTypes.string,
    modalContent: PropTypes.node,
};

export default ResultListing;
