/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withPrefix, Link, graphql } from 'gatsby';

// Helpers
import { filterResults } from '../../helpers/helpers';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import Select from '../../components/select';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import InputSwitch from '../../components/input-switch';
import ResultListing from '../../components/result-listing';
import Tips from '../../components/tips';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';

const PensionContribution = (props) => {
	const { getShareLink } = withShareResults();
	const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

	// React Redux hooks
	const dispatch = useDispatch();
	const query = useSelector(state => state.pensionContribution);

	useEffect(() => {
		dispatch({ type: 'APPLY_PENSION_CONTRIBUTION_URL_PARAMS' });

        return () => {
            dispatch({ type: 'RESET_YEAR' });
            dispatch({ type: 'RESET_PENSION_CONTRIBUTION_SELECTIONS' });
        };
    }, []);

	let grossYearlyIncome = (query.paidPer === 'year') ? query.grossIncome :
		(query.paidPer === 'month') ? (query.grossIncome * 12) :
		(query.paidPer === 'fortnight') ? (query.grossIncome * 26) :
		(query.paidPer === 'week') ? (query.grossIncome * 52) :
		(query.paidPer === 'day') ? ((query.grossIncome * 5) * 52) :
		(query.paidPer === 'hour') ? (((query.grossIncome * 7.5) * 5) * 52) :
		null;

	let contributedIncome = query.partialContribution && grossYearlyIncome <= query.qualifyingEarningsCap ? grossYearlyIncome - query.qualifyingEarningsLowerThreshold :
		query.partialContribution && grossYearlyIncome > query.qualifyingEarningsCap ? query.qualifyingEarningsCap - query.qualifyingEarningsLowerThreshold :
		grossYearlyIncome;

	let calcYourContribution = contributedIncome * (query.yourContribution / 100);

	let calcEmployerContribution = contributedIncome * (query.employerContribution / 100);

	let calcTotalContribution = calcYourContribution + calcEmployerContribution;

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />
			
			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.html} />
				)}
				description={pageInfo.frontmatter.description}
				breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
			/>

			<Section label='Calculator'>
				<Form>
					<Form.Fieldset>
						<Form.Row>
							<InputField
								id='gross-income'
								labelText='Gross income'
								modalHeading={definitions[2].frontmatter.title}
								modalContent={<PageContent content={definitions[2].html} />}
								placeholder='0'
								symbol='currency'
								min={0}
								onChange={(event) => dispatch({
                                    type: 'SET_PENSION_CONTRIBUTION_GROSS_INCOME',
                                    payload: event.target.value,
                                })}
								required
								value={query.grossIncome}
							/>

							<Select
								labelText="Paid per"
								id="paid-select"
								onChange={(event) => dispatch({
                                    type: 'SET_PENSION_CONTRIBUTION_PAID_PER',
                                    payload: event.target.value,
                                })}
								value={query.paidPer}
							>
								<Select.Option value="year">Year</Select.Option>
								<Select.Option value="month">Month</Select.Option>
								<Select.Option value="fortnight">Fortnight</Select.Option>
								<Select.Option value="week">Week</Select.Option>
								<Select.Option value="day">Day</Select.Option>
								<Select.Option value="hour">Hour</Select.Option>
							</Select>
						</Form.Row>
						
						<Form.Row>
							<InputField
								id='your-contribution'
								labelText='Your contribution'
								modalHeading={definitions[0].frontmatter.title}
								modalContent={<PageContent content={definitions[0].html} />}
								placeholder='0'
								symbol='percentage'
								min={0}
								max={100}
								onChange={(event) => dispatch({
                                    type: 'SET_PENSION_CONTRIBUTION_YOURS',
                                    payload: event.target.value,
                                })}
								required
								value={query.yourContribution}
							/>

							<InputField
								id='employer-contribution'
								labelText='Employer contribution'
								modalHeading={definitions[1].frontmatter.title}
								modalContent={<PageContent content={definitions[1].html} />}
								placeholder='0'
								symbol='percentage'
								min={0}
								max={100}
								onChange={(event) => dispatch({
                                    type: 'SET_PENSION_CONTRIBUTION_EMPLOYER',
                                    payload: event.target.value,
                                })}
								required
								value={query.employerContribution}
							/>
						</Form.Row>

						<Form.Row>
							<InputSwitch
								id="employer-partial-contribution"
								onChange={() => dispatch({
                                    type: 'SET_PENSION_CONTRIBUTION_PARTIAL',
                                    payload: !query.partialContribution,
                                })}
								checked={query.partialContribution}
								modalHeading={definitions[3].frontmatter.title}
								modalContent={<PageContent content={definitions[3].html} />}
							>
								Employer contributes on a part of my salary
							</InputSwitch>
						</Form.Row>
					</Form.Fieldset>
				</Form>
			</Section>

			<Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
				<ResultListing
					select={[
						{name: 'Year', value: 'year'},
						{name: 'Month', value: 'month'},
						{name: 'Week', value: 'week'},
						{name: 'Day', value: 'day'},
					]}
					selectID='results-filter'
					selectOnChange={(event) => dispatch({
						type: 'SET_PENSION_CONTRIBUTION_FILTER_RESULTS',
						payload: event.target.value,
					})}
					selectDefaultValue='month'
					value={query.filterResults}
				>
					<ResultListing.Item 
						heading='Your contribution'
						result={filterResults(calcYourContribution, query.filterResults, 7, 24)}
						subheading={`per ${query.filterResults}`}
					/>

					<ResultListing.Item 
						heading='Employer contribution'
						result={filterResults(calcEmployerContribution, query.filterResults, 7, 24)}
						subheading={`per ${query.filterResults}`}
					/>

					<ResultListing.Item 
						heading='Total pension contribution'
						result={filterResults(calcTotalContribution, query.filterResults, 7, 24)}
						subheading={`per ${query.filterResults}`}
					/>
				</ResultListing>

				<div className='summary-card__controls'>
					<ShareResults url={getShareLink(query)} />
				</div>
			</Section>

			<Tips text={(
				<>
					<h2><span role='img' aria-hidden='true'>&#129300;</span> Want more information?</h2>
					<p>Get a full breakdown of your salary and pension with our <Link to="/personal-finance/salary">Salary Calculator</Link></p>
				</>
			)} />
        </Default>
    );
};

export default PensionContribution;

export const PensionContributionQuery = graphql`
    query PensionContributionQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
		definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/gross-income|src/definitions/employee-pension-contribution|src/definitions/employer-pension-contribution|src/definitions/qualifying-earnings/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;