/* eslint-disable no-useless-escape */
import moment from 'moment';

export const formatNumber = (number, disableNegative, removeCurrency) => {
    const value = disableNegative && number < 0 ? 0 : number;
    
    const formatter = new Intl.NumberFormat('en-GB', removeCurrency ? {} : {
        style: 'currency',
        currency: 'GBP',
    });

    const result = formatter.format(value);

    return result;
};

// Filter value by time period
export const filterResults = (value, selectedPeriod, workingDays, workingHours, removeCurrency) => {
    let result;
    const daysPerWeek = workingDays || 5,
        hoursPerDay = workingHours || 7.5;

    switch(selectedPeriod) {
        case 'year':
            result = formatNumber(value, true, removeCurrency);
            break;
        case 'month':
            result = formatNumber(value / 12, true, removeCurrency);
            break;
        case 'week':
            result = formatNumber(value / 52, true, removeCurrency);
            break;
        case 'day':
            result = formatNumber(value / 52 / daysPerWeek, true, removeCurrency);
            break;
        case 'hour':
            result = formatNumber(value / 52 / daysPerWeek / hoursPerDay, true, removeCurrency);
            break;
        default:
    };

    return result;
};

// Date string formatting
export const formatDate = (date, format) => {
    const newDate = new Date(date);
    const formattedDate = moment(newDate).format(format || 'Do MMMM, YYYY');
    return formattedDate;
};

// Browser compatible .replaceAll()
export const replaceAll = (str, find, replace) => {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
};
