/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/tips.scss';

const Tips = ({
    text,
}) => {
    const [state, setState] = useState({
        isAnimating: false,
        isHidden: false,
    });

    const baseClass = 'tips__content';
    const animatingClass = state.isAnimating ? 'tips__content--animating' : '';
    const hiddenClass = state.isHidden ? 'tips__content--hidden' : '';
    const classes = [baseClass, animatingClass, hiddenClass].filter(Boolean).join(' ');

    const handleClose = () => {
        setState({...state, isAnimating: true});

        setTimeout(() => {
            setState({...state, isHidden: true});
        }, 300);
    };

    const handleOpen = () => {
        setState({...state, isHidden: false, isAnimating: false});
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth < 768) {
            setState({...state, isHidden: true});
        }
    }, []);

    return (
        <div className='tips'>
            <button
                className='tips__button'
                onClick={handleOpen}
            >
                <span className='accessible'>Close tips popup</span>
            </button>

            <div className={classes}>
                <button
                    className='tips__close'
                    onClick={handleClose}
                >
                    <span className='accessible'>Close tips popup</span>
                </button>

                <div className='tips__text'>
                    {text}
                </div>
            </div>
        </div>
    );
};

Tips.propTypes = {
    text: PropTypes.node.isRequired,
};

export default Tips;
