/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/select.scss';

// Component Imports
import Label from '../label';
import InfoModal from '../info-modal';

const Select = ({
    className,
    variant,
    id,
    name,
    size,
    labelText,
    children,
    multiple,
    disabled,
    required,
    tooltip,
    tooltipHeading,
    onChange,
    value,
    defaultValue,
}) => {
    // Classname definitions
    const baseClass = 'select';
    const variantClass = variant ? `select--${variant}` : '';
    const customClass = className;
    const disabledClass = disabled ? 'select--disabled' : '';
    const classes = [baseClass, variantClass, customClass, disabledClass].filter(Boolean).join(' ');

    const selectID = id + '-input';

    return (
        <div className={classes}>
            <div className='select__label-wrapper'>
                <Label htmlFor={selectID} disabled={disabled} required={required}>
                    {labelText}
                </Label>

                { (tooltip && !disabled) && (
                    <InfoModal heading={tooltipHeading}>
                        {tooltip}
                    </InfoModal>
                )}
            </div>
            
            <div className='select__input-wrapper'>
                <select
                    className="select__input"
                    id={selectID}
                    name={name}
                    size={size}
                    multiple={multiple || ''}
                    aria-multiselectable={multiple}
                    disabled={disabled || ''}
                    required={required || ''}
                    onChange={onChange}
                    value={value}
                    defaultValue={defaultValue}
                >
                    {children}
                </select>
            </div>
        </div>
    );
};

Select.defaultProps = {
    labelText: 'Please select an option',
};

Select.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    labelText: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    children: PropTypes.node.isRequired,
    tooltipHeading: PropTypes.string,
    tooltip: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
};

Select.Option = ({
    className,
    variant,
    value,
    children,
    disabled,
}) => {
    // Class definitions
    const baseClass = 'select__option';
    const variantClass = variant ? `select__option--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <option
            className={classes}
            value={value}
            disabled={disabled || ''}
            aria-disabled={disabled || undefined}
        >
            {children}
        </option>
    );
};

Select.Option.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    value: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default Select;
