/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/form.scss';

const Form = ({
    className,
    variant,
    id,
    name,
    children,
    onSubmit,
}) => {
    // Class definitions
    const baseClass = 'form';
    const variantClass = variant ? `form--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <form
            className={classes}
            id={id}
            name={name}
            onKeyDown={function(event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    return false;
                }
                
                return true;
            }}
            onSubmit={onSubmit}
        >
            {children}
        </form>
    );
};

Form.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func,
};


Form.Fieldset = ({
    children,
    legend,
    className,
}) => {
    const baseClass = 'form__legend';
    const customClass = className;
    const classes = [baseClass, customClass].filter(Boolean).join(' ');

    return (
        <fieldset className='form__container container'>
            {legend && (
                <h2 className={classes}>{legend}</h2>
            )}
            {children}
        </fieldset>
    );
};

Form.Fieldset.propTypes = {
    children: PropTypes.node.isRequired,
    legend: PropTypes.string,
};

Form.Row = ({
    children,
}) => {
    return (
        <div className='form__row'>
            {children}
        </div>
    );
};

Form.Row.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Form;
